import React from 'react'
import Button from '../components/button'

import './styleguide.scss'

const typography = (text) => (
  <div>
    <b>Typography</b>
    <ul>
      <li><h1>H1 {text}</h1></li>
      <li><h2>H2 {text}</h2></li>
      <li><h3>H3 {text}</h3></li>
      <li><h4>H4 {text}</h4></li>
      <li><h5>H5 {text}</h5></li>
      <li><h6>H6 {text}</h6></li>
    </ul>
  </div>
)

const buttons = (
  <div>
    <b>Buttons</b>
    <div className='styleguide-buttons'>
      <Button isButton>Primary</Button>
    </div>
  </div>
)

const colors = (
  <div>
    <b>Colors</b>
    <div className='styleguide-colors'>
      <div className='primary-background'>Primary</div>
      <div className='secondary-background'>Secondary</div>
      <div className='tertiary-background'>Tertiary</div>
    </div>
  </div>
)

const Styleguide = () => {
  const text = 'Lorem Ipsum is simply dummy text of the printing and typesetting'

  return (
    <div className='styleguide-body'>
      <div className='header'><b>Styleguide</b></div>
      {typography(text)}
      {colors}
      {buttons}
    </div>
  )
}

export default Styleguide
